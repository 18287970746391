<template>
  <div class="cont">

    <van-swipe class="my-swipe " :loop="false" indicator-color="white" style="width: 100% ;">
      <van-swipe-item class="top" v-for="(swipeitem , swipeidx  ) in Math.ceil( (siteData.length+1) / 8 )  ">
        <div v-touch:touchhold="(event) => { longtapHandler(event, index) }" 
            v-touch:tap="(event) => { gotoWeb(event, item) }" 
            class="item"  
            v-for="(item , index ) in siteData.slice( swipeidx*8 ,  swipeidx*8+8  )">
          <div class="icon icondefault" v-if="defaultData.includes( item.url )" :class="'bg-'+item.title "></div>
          <div class="icon iconno" v-else>
            {{ item.title.substring(0, 2) }}
          </div>
          <div class="title">
            {{  item.title }}
          </div>
        </div>

        <div @click="addwebshowClick" class="item" v-if="(swipeidx + 1 ) == Math.ceil( (siteData.length+1) / 8 )">
          <div class="icon icon_add"></div>
          <div class="title">
            add
          </div>
        </div>

      </van-swipe-item>

    </van-swipe>





    <!-- <div class="top">
      
    </div> -->

    <van-sticky>
      <van-tabs background="#fff" v-model="activeName" title-active-color="#685FF5" color="#685FF5">
        <van-tab title="AI news" name="ainews">
        </van-tab>
        <van-tab title="sports"  name="sports">
        </van-tab>
        <van-tab title="entertainment"  name="entertainment">
          
        </van-tab>
        <van-tab title="funny"  name="funny">
          
        </van-tab>
        <van-tab title="business"  name="business">
          
        </van-tab>
        <van-tab title="headline"  name="headline">
          
        </van-tab>
        <van-tab title="food"  name="food">
          
        </van-tab>
        <van-tab title="lifestyle"  name="lifestyle">
          
        </van-tab>
      </van-tabs>
    </van-sticky>

    <div class="news_cont">
      <van-list v-model="loading" loading-text="loading"  :finished="finished" finished-text="ending" :error.sync="error" error-text="error, click loading again." @load="onLoad">
        <van-row class="list-item" v-for="(item,idx) in newsList" :key="idx"  @click="newsLink(item, item._id, 1)">
          <van-col :span="item.images[0] ? 15 : 23">
            <!-- <span class="van-multi-ellipsis--l3 p" style="font-size: .4rem;line-height: .6rem;">{{item.title}}</span> -->
            <div class="van-multi-ellipsis--l2" style="font-size: .4rem;line-height: .6rem;">{{item.title}}</div>

            

            <div class="channel_name">
              <template v-if="activeName == 'ainews' ">
                <span
                  class="p"
                  v-if="item.author && !item.author.includes('ai') "
                  > 
                  <img src="../../assets/images/ai.jpg" alt="" style="width: 15px;height: 15px;margin-right: 3px;margin-top: -2px;">
                  AI news
                </span>

                <span v-else class="p">
                  <img src="../../assets/images/ai.jpg" alt="" style="width: 15px;height: 15px;margin-right: 3px;margin-top: -2px;">
                  AI news
                </span>
              </template>
              <template v-else>
                <span class="p">
                  {{ item.channel_name ? item.channel_name : item.additional.contentSourceDisplay }}
                </span>
              </template>
              <!-- {{  item.channel_name ?   item.channel_name :  item.additional.contentSourceDisplay  }} -->
            </div>
            <!-- <span class="p" style="display:inline-block;margin-top: .5rem;font-size: .5333rem;color:#9E9E9E">{{item.channel_name}} </span> -->
          </van-col>
          <van-col span="8" offset="1" v-if="item.images[0]">
            <img style="width:100%;height:2.5rem;object-fit: cover;border-radius: 10px" :src="item.images[0]" alt="">
          </van-col>
          
          
          <!-- <van-col :span="24">
            <span class="van-multi-ellipsis--l2 p title" >{{item.title}}</span>
            <div v-if="item.images[0] &&  ( (idx%10) == 0 )">
              <img style="width:100%;height:7.1rem;object-fit: cover;border-radius: 10px" :src="item.images[0]" alt="">
            </div>
            <template v-if="activeName == 'ainews' ">
              <span
                class="p"
                v-if="item.author && !item.author.includes('ai') "
                > 
                <img src="../../assets/images/ai.jpg" alt="" style="width: 15px;height: 15px;margin-right: 3px;margin-top: -2px;">
                AI news
              </span>

              <span v-else class="p">
                <img src="../../assets/images/ai.jpg" alt="" style="width: 15px;height: 15px;margin-right: 3px;margin-top: -2px;">
                AI news
              </span>
            </template>
            <template v-else>
              <span class="p">
                {{ item.channel_name ? item.channel_name : item.additional.contentSourceDisplay }}
              </span>
            </template>
          </van-col> -->

        </van-row>
      </van-list> 
    </div>
    <!-- @submit="onSubmit" -->
    <van-action-sheet v-model="addwebshow" title="Add Website" >
      <van-form @submit="onSubmit">
        <van-field
          v-model="title"
          name="title"
          label=""
          placeholder="Title"
          :rules="[{ required: true, message: 'please enter title' }]"
        />
        <van-field
          v-model="website"
          name="website"
          label=""
          placeholder="Website"
          :rules="[{ pattern : webpattern , message: 'Please enter a compliant URL' }]"
        />
        <div style="margin: 10px 0;display: flex;justify-content: space-around;">
          <van-button round  type="default"  native-type="button" @click.stop="addwebshow = false">CANCEL</van-button>
          <van-button round  type="info" native-type="submit"   style="background-color: #685FF5;border-color: #685FF5;">CONFIRM</van-button>
        </div>
      </van-form>
    </van-action-sheet>

    <div>
      <BackTop> </BackTop>
    </div>
  </div>
</template>

<script>
import BackTop from "../../components/BackTop.vue";

  document.oncontextmenu = function (e) {
    e.preventDefault();
  };



export default {
  data() {
    return {
      activeName: '',
      addwebshow : false , 
      error: false,
      loading: false,
      finished: true,
      time: parseInt(new Date().getTime() / 1000 / 1800 ),
      session: 'init' ,
      newsList : [] ,
      news: {
        offset: 0,
        limit: 20
      },

      siteData : [] , 
      defaultData : [ "https://www.facebook.com" , "https://www.twitter.com" , "https://www.instagram.com" , "https://www.vimeo.com" , "https://www.dailymotion.com" , "https://www.tiktok.com" ] , 
      defaultDataList : [
        {
          "title": "Facebook",
          "url": "https://www.facebook.com",
          "icon": "facebook.png",
        },
        {
          "title": "X",
          "url": "https://www.twitter.com",
          "icon": "xtwitter.png",
        },
        {
          "title": "Instagram",
          "url": "https://www.instagram.com",
          "icon": "instagram.png",
        },
        {
          "title": "Vimeo",
          "url": "https://www.vimeo.com",
          "icon": "vimeo.png",
        },
        {
          "title": "Dailymotion",
          "url": "https://www.dailymotion.com",
          "icon": "dailymotion.png",
        },{
          "title": "TikTok",
          "url": "https://www.tiktok.com",
          "icon": "tiktok.png",
        }] ,
        title : undefined , 
        website : undefined , 
        webpattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/ , 

    };
  },
  components: {
    BackTop
  },
  methods: {
    longtapHandler( event , index) {
      event.preventDefault()
      // this.$toast('提示文案');
      let _this = this ; 
      this.$dialog.confirm({
        message: 'Confirm to delete this website?',
        confirmButtonText : "confirm" ,
        cancelButtonText : "cancel"
      }).then(() => {
        this.siteData.splice( index ,1  );
        localStorage.setItem( 'initStorageWebsite' ,   JSON.stringify(  this.siteData  )  );
        this.$forceUpdate() ;
      })
    } ,
    gotoWeb( event , item ){
      event.preventDefault()
      window.open(  item.url ,  "_blank");  
    },
    onLoad( type ){
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        if (this.news.offset > 0) {
          setTimeout(() => {
            this.getNews( type );
            this.news.offset = parseInt(this.news.offset) + this.news.limit;
          }, 100);
        } else {
          this.getNews( type );
          this.news.offset = parseInt(this.news.offset) + this.news.limit;
        }
        // 加载状态结束
        /* if (this.news.offset >= 40) {
          this.finished = true;
        } */
      }, 100);
    },
    getNews (  type  ) {
      const self = this
      let apiURL 

      // 判断国家  IN, TH, VN, ID, PH   
      let language =  navigator.language || navigator.userLanguage || "en-US";//常规浏览器语言和IE浏览器
      let cty = language.split("-")[1] ? language.split("-")[1] : 'default'  //获取地区 如US
      let lang = language.split("-")[0] ? language.split("-")[0] : 'default'  //获取语言 如en

      self.time = parseInt(new Date().getTime() / 1000 ) 
      apiURL = `https://innews.infohubnews.app/ad/mixnews?cty=${ cty }&lang=${ lang }&session=${ this.session }&platform=web&c=${ this.activeName }&cc=testnews&offset=${ self.news.offset }&limit=${ self.news.limit }&ts=${ self.time }`
      if( this.activeName == "soccer" ) apiURL = `https://innews.infohubnews.app/v1/channels/news?lang=en&cty=US&ch=goal&c=sports`
      if( this.activeName == "ainews" ) apiURL = `https://innews.infohubnews.app/v1/channels/news?cty=US&lang=en&offset=${self.news.offset}&limit=${ self.news.limit }&ch=telegram&c=finance`
      this.$axios({
        method: 'get',
        url: apiURL 
      }).then(response => {
        // console.log(response, 'success');// 成功的返回
        // self.news.limit = 10
        if (response.status == 200) {
          if( this.activeName == "ainews" ){
            if( response.data.Result.length < 20 ) {
              self.newsList = self.newsList.concat(  response.data.Result )
              this.finished = true;
            } else {
              self.newsList = self.newsList.concat(  response.data.Result )
              this.finished = false;

            }
          } else {
            if (response.data.ads && response.data.ads.length > 0) {
              this.session = response.data.session ? response.data.session : 'init'

              
              for (let i in response.data.ads) {
                //self.newsList.push(response.data.Result[i]);
                //console.info(response.data.Result[i]);
                if(response.data.ads[i]._id  ){  /*暂时处理*/
                  response.data.ads[i].type = this.activeName 
                  self.newsList.push(response.data.ads[i]);
                }
              }

              if( self.newsList[0].images.length == 0 ){
                let _idx = 0 ;
                for( let i = 0 ; i < self.newsList.length ; i++ ){
                  if( self.newsList[i].images.length > 0 ){
                    _idx = i ;
                    break
                  } 
                }
                let elementToMove = self.newsList.splice(_idx, 1)[0]; // 移除想要移到第一项的元素，并保存它
                self.newsList.unshift( elementToMove );
              }
              
            }else {
              this.finished = true;
            }
          }
        } else {
          this.error = true;
        }
        this.loading = false;
      }).catch(error => console.log(error, 'error')); // 失败的返回值
    },
    newsLink (item, id, odd) {
      if( item.channel_id == "goal" || this.activeName == "soccer"  ) {
        window.location.href = item.link;
        return
      }
      let self = this  
      setTimeout(function(){
        if( item._id && !item.channel_id   ){
          //去中间页
          self.$router.push({ 
              path:'/detail',
              query: {
                id: item._id   , 
                category : self.activeName, 
                locale : self.locale , 
                language : item.language , 
                cc :  self.cc ,  
                c : self.c ,
                type :  self.activeName ,        
              }
          })
        } else if( item._id && ( item.channel_id &&  item.channel_id != "afnews"  ) ){
          self.$router.push({ 
              path:'/detail',
              query: {
                id: item._id   , 
                category : self.activeName, 
                locale : self.locale , 
                language : item.language , 
                cc :  self.cc ,  
                c : self.c ,
                type :  self.activeName ,                  
              }
          })
        } else {
          window.location.href = item.link;
        }
        
      },300)

    },
    getStorageWebsite(){
      let flag = localStorage.getItem("isInitStorageWebsite"); 
      if( !flag ){ //没有写入默认数据
        localStorage.setItem( 'initStorageWebsite' ,   JSON.stringify(  this.defaultDataList  )  );
        this.siteData = JSON.parse(  localStorage.getItem( 'initStorageWebsite' )  )
        localStorage.setItem("isInitStorageWebsite" , true );
      } else { //写入了默认数据
        if( localStorage.getItem( 'initStorageWebsite' )  ){
          this.siteData = JSON.parse(  localStorage.getItem( 'initStorageWebsite' )  )
        } else {
          this.siteData = []
        }
      }
    },
    addwebshowClick(){
      this.website = undefined ; 
      this.title = undefined ;
      this.addwebshow = true ;
    },
    onSubmit(  values) {
      console.log(  values )
      if( !values.website.toLowerCase().startsWith( 'http') ){
        values.website = 'http://' + (values.website.trim() ); 
      }

      this.siteData.push({
        "title": values.title ,
        "url": values.website ,
        "icon": "",
      })
      localStorage.setItem( 'initStorageWebsite' ,   JSON.stringify(  this.siteData  )  );
      this.$forceUpdate() ;
      this.addwebshow = false ;
      window.scrollTo( 0 , 0 )
    },

  },
  created(){
    this.activeName = 'ainews'
    this.finished = false 
    this.loading = true ;
    this.getStorageWebsite();
  },
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    activeName: function (newAct , oldAct ) {
      this.activeName = newAct 
      this.newsList = []
      this.news = {
        offset: 0,
        limit: 20
      }
      this.finished = false 
      this.loading = true 
      window.scrollTo(0 , 0 )
      this.onLoad( )
    }
  },

}
</script>

<style scoped lang="scss">
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cont {
  width: 100%;

  .top {
    padding: 0 0.4rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0.24rem;
    box-sizing: border-box;
    .item {
      width: 2.15rem;
      height: 2.26rem;
      padding: 0 0.07rem;
      margin-bottom: .4rem;
      .icon {
        width: 1.07rem;
        height: 1.07rem;
        // background-color: pink;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        border-radius: 50%;
      }
      .icon_add {
        background-image: url("../../assets/images/add.png");
      }
      .title {
        font-size: 0.35rem;
        color: #333333;
        line-height: 0.48rem;
        height: auto;
        margin-top: .21rem;
        text-align: center;
        word-wrap:break-word;
        
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

      }
      .iconno {
        line-height: 1.00rem;
        text-align: center;
        overflow: hidden;
        background-color: #000;
        color: #fff;
      }
    }
    // .item:nth-child(5){
    //   margin-right: 0;
    // }
  }

  .news_cont {
    height: calc(100% - 66px);
    padding: 0 0.37rem;
    .title {
      font-size: 0.48rem !important;
      color: #333333 !important;
      line-height: 0.67rem !important;
      border-bottom: none !important;
      padding-top: 0.2rem !important;
    }
    .p {
      display: flex;
      align-items: center;
      font-size: 0.29rem;
      color: #999;
      padding-bottom: 0.3rem;
    }
    .list-item {
      border-bottom: 1px solid #EAEAEA;
      margin-top: 0.32rem;
      margin-bottom: 0.32rem;
    }
    .channel_name {
      font-size: 0.35rem;
      color: #666666;
      margin-top: .72rem;
    }
  }
}
.bg-Facebook {
  background: url(../../assets/images/Facebook.png) center no-repeat;
}
.bg-X {
  background: url(../../assets/images/X.png) center no-repeat;
}
.bg-Instagram {
  background: url(../../assets/images/Instagram.png) center no-repeat;
}
.bg-Vimeo {
  background: url(../../assets/images/Vimeo.png) center no-repeat;
}
.bg-Dailymotion {
  background: url(../../assets/images/Dailymotion.png) center no-repeat;
}
.bg-TikTok {
  background: url(../../assets/images/TikTok.png) center no-repeat;
}
</style>